import styles from "./ChatColor.module.scss"

export default function ChatColor({color, onClick, text, active}) {
	let classNames = [styles.root, active ? styles.active : ""].join(" ")

	return (
		<div className={classNames} onClick={onClick}>
			{!!color && <div className={styles.color} style={{background: color}} />}
			{text}
		</div>
	)
}
