import {createContext} from "react"

import {useModal} from "./hooks/useModal"
import ChatModal from "./components/ChatPage/ChatModal/ChatModal"

const ModalContext = createContext(undefined)

const ModalProvider = ({children}) => {
	const {modal, handleModal, modalTitle, modalMessage, modalDepType} = useModal()

	return (
		<ModalContext.Provider value={{modal, handleModal, modalTitle, modalMessage, modalDepType}}>
			<ChatModal />
			{children}
		</ModalContext.Provider>
	)
}

export {ModalContext, ModalProvider}
