import {animated, easings, useSpring} from "@react-spring/web"

import styles from "./MoreInfo.module.scss"

export default function MoreInfo({option, closeMoreInfo, isMoreInfoOpen}) {
	const {title, description, complectation} = option

	const optionSprings = useSpring({
		from: {y: isMoreInfoOpen ? 200 : 0, opacity: isMoreInfoOpen ? 0 : 1},
		to: {y: isMoreInfoOpen ? 0 : 200, opacity: isMoreInfoOpen ? 1 : 0},
		config: {easings: easings.easeInOutBack, duration: 400},
	})

	const overlaySprings = useSpring({
		from: {opacity: isMoreInfoOpen ? 0 : 1},
		to: {opacity: isMoreInfoOpen ? 1 : 0},
	})
	return (
		<>
			<animated.div style={{...optionSprings, visibility: isMoreInfoOpen ? "visible" : "hidden"}} className={styles.root}>
				<div className={styles.titleWrapper}>
					<span className={styles.title} dangerouslySetInnerHTML={{__html: title}}></span>
				</div>
				{description ? (
					<div className={styles.text} dangerouslySetInnerHTML={{__html: description}}></div>
				) : (
					<div className={styles.complectations}>
						{Object.keys(complectation).map((key, idx) => (
							<div className={styles.complectation} key={idx}>
								<img src={`/images/icons/${key}.svg`} alt='Иконка' />
								<p>{complectation[key]}</p>
							</div>
						))}
					</div>
				)}
				<button className={styles.close} onClick={closeMoreInfo} />
			</animated.div>
			<animated.div
				style={{...overlaySprings, visibility: isMoreInfoOpen ? "visible" : "hidden"}}
				onClick={closeMoreInfo}
				className={styles.overlay}
			></animated.div>
		</>
	)
}
