import {useEffect, useState} from "react";

export const useModal = () => {
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("Оставить заявку");
    const [modalDepType, setModalDepType] = useState("ОПНА");
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setModal(false)
            }
        }
        window.addEventListener("keydown", close)
        return () => window.removeEventListener("keydown", close)
    }, [modal])

    const handleModal = (title = '', message = '', depType = '') => {
        setModal(!modal);
        if (title) {
            setModalTitle(title);
        }
        if (depType) {
            setModalDepType(depType);
        } else {
            setModalDepType("ОПНА");
        }
        setModalMessage(message)
    };

    return {modal, handleModal, modalTitle, modalMessage, modalDepType};
};
