import {useEffect, useState} from "react"
import {getDataFromApi} from "../../api/getDataFromApi"
import {setMultiadminData} from "../../utils/setMultiadminData"

import styles from "./ChatPage.module.scss"
import ChatHeader from "../../components/ChatPage/ChatHeader/ChatHeader"
import ChatBanner from "../../components/ChatPage/ChatBanner/ChatBanner"
import Chat from "../../components/ChatPage/Chat/Chat"
import ChatPopup from "../../components/ChatPage/ChatPopup/ChatPopup"
import ChatFooter from "../../components/ChatPage/ChatFooter/ChatFooter"

export default function ChatPage({models}) {
	const [data, setData] = useState(null)
	const [send, setSend] = useState(false)
	useEffect(() => {
		getDataFromApi("jetour", "sales.jetour-maximum.ru/chat").then((r) => setData(r))
	}, [])

	models = setMultiadminData(models, data)
	const maxBenefit = data?.find((model) => model.id === "jetour-max-benefit")?.benefit || "720 000"

	return (
		<div style={{position: "relative"}} className={styles.root}>
			<div className={styles.background}></div>
			<ChatHeader />
			{!send && (
				<main className={styles.main}>
					<div className={styles.container}>
						<ChatBanner />
					</div>
					<div className={styles.container}>
						<Chat maxBenefit={maxBenefit} setSend={setSend} models={models} />
					</div>
				</main>
			)}

			<div className={`${styles.finish}  ${send ? styles.finishActive : ""}`}>
				<ChatPopup title={"Ваша заявка принята!<br />Мы скоро свяжемся с Вами"} />
			</div>
			<ChatFooter />
		</div>
	)
}
