function ChatIcon({className, name = "car", width = "24px", height = "24px", format}) {
	return <img loading={"lazy"} style={!className ? {width: width, height: height} : {}} className={className} src={`/images/${name}.${format}`} alt={name} />
}

ChatIcon.Pin = () => (
	<svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M6.00002 11.8686C7.37502 10.7425 8.42017 9.6578 9.13546 8.61439C9.85073 7.571 10.2084 6.57425 10.2084 5.62414C10.2084 4.82316 10.0663 4.14548 9.78208 3.59112C9.4979 3.03678 9.14346 2.59103 8.71877 2.25389C8.29409 1.91677 7.83671 1.67211 7.34664 1.51993C6.85656 1.36774 6.41018 1.29164 6.0075 1.29164C5.60483 1.29164 5.1605 1.36857 4.6745 1.52241C4.18851 1.67626 3.72864 1.92092 3.29487 2.25639C2.86111 2.59187 2.50214 3.03678 2.21796 3.59112C1.93376 4.14548 1.79167 4.82316 1.79167 5.62414C1.79167 6.57425 2.15278 7.571 2.875 8.61439C3.59723 9.6578 4.63891 10.7425 6.00002 11.8686ZM6.00002 13.2436C4.23613 11.8921 2.91322 10.5884 2.03129 9.33252C1.14935 8.07664 0.708374 6.84366 0.708374 5.63358C0.708374 4.71959 0.868811 3.9272 1.18969 3.25639C1.51056 2.58559 1.92335 2.02331 2.42804 1.56956C2.93274 1.11582 3.50052 0.775519 4.13139 0.548644C4.76226 0.321783 5.38513 0.208351 6.00002 0.208351C6.61491 0.208351 7.23778 0.321783 7.86864 0.548644C8.49952 0.775519 9.0673 1.11582 9.572 1.56956C10.0767 2.02331 10.4895 2.58559 10.8104 3.25639C11.1312 3.9272 11.2917 4.71959 11.2917 5.63358C11.2917 6.84366 10.8542 8.07664 9.97917 9.33252C9.10418 10.5884 7.7778 11.8921 6.00002 13.2436ZM6.00444 6.79164C6.36474 6.79164 6.66935 6.66571 6.91827 6.41383C7.1672 6.16196 7.29167 5.85587 7.29167 5.49558C7.29167 5.13528 7.16573 4.83066 6.91385 4.58175C6.66198 4.33282 6.35589 4.20835 5.9956 4.20835C5.6353 4.20835 5.33069 4.33429 5.08177 4.58616C4.83284 4.83804 4.70837 5.14412 4.70837 5.50441C4.70837 5.86472 4.83431 6.16933 5.08619 6.41825C5.33806 6.66718 5.64415 6.79164 6.00444 6.79164ZM0.708374 15.7916V14.7084H11.2917V15.7916H0.708374Z'
			fill='#ffffff'
		/>
	</svg>
)

ChatIcon.Point = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.582 2.55521C6.46131 1.10905 8.60373 0.254499 10.9247 0.0291214C11.5824 -0.0366116 13.1235 0.0150356 13.8141 0.12303C15.0639 0.320232 16.1351 0.658298 17.2909 1.22643C18.5547 1.84622 19.4521 2.48948 20.4904 3.52245C21.524 4.56012 22.1677 5.45693 22.7878 6.71998C23.2013 7.56045 23.408 8.1098 23.6335 8.95496C23.9295 10.0818 24 10.6969 24 12.0022C24 13.3075 23.9295 13.9226 23.6335 15.0495C23.408 15.8947 23.2013 16.444 22.7878 17.2845C22.1677 18.5475 21.524 19.4443 20.4904 20.482C19.4521 21.515 18.5547 22.1583 17.2909 22.778C16.1304 23.3462 15.0874 23.6795 13.7906 23.8908C12.8745 24.0364 11.1361 24.0364 10.2199 23.8908C8.92321 23.6795 7.88019 23.3462 6.71972 22.778C5.45588 22.1583 4.55851 21.515 3.52019 20.482C2.48657 19.4443 1.8429 18.5475 1.22273 17.2845C0.654236 16.1247 0.320658 15.0824 0.109235 13.7865C-0.0364117 12.8709 -0.0364117 11.1336 0.109235 10.218C0.23139 9.47145 0.480399 8.50421 0.710615 7.87503C0.91734 7.31159 1.49523 6.12837 1.79122 5.66353C2.47717 4.5836 3.66583 3.25952 4.582 2.55521ZM19.1749 4.83715C17.5117 3.1797 15.4162 2.16081 13.1235 1.91195C12.5973 1.85091 11.3475 1.85561 10.8307 1.91195C8.59433 2.1655 6.47541 3.19848 4.83571 4.83715C2.88592 6.78572 1.857 9.26485 1.857 12.0022C1.857 14.7396 2.88592 17.2188 4.83571 19.1673C6.78549 21.1159 9.26619 22.1442 12.0053 22.1442C14.7444 22.1442 17.2251 21.1159 19.1749 19.1673C21.1247 17.2188 22.1536 14.7396 22.1536 12.0022C22.1536 9.26485 21.1247 6.78572 19.1749 4.83715ZM13.4104 10.9651C15.426 8.74421 16.3374 7.76758 16.4784 7.69245C17.2113 7.29804 18.0899 8.10095 17.7516 8.86159C17.6858 9.00245 16.6052 10.2279 14.383 12.6695C11.7237 15.5947 11.0754 16.2802 10.925 16.3413C10.6572 16.4493 10.3659 16.4352 10.1357 16.3084C9.84441 16.1535 6.39117 12.6226 6.27372 12.369C6.14686 12.0967 6.15626 11.8807 6.30191 11.5896C6.49923 11.1905 6.92678 10.998 7.35432 11.1107C7.54225 11.1576 7.73018 11.3266 9.0504 12.646L10.5351 14.1251L13.4104 10.9651Z'
			fill='#c78e66'
		/>
	</svg>
)

ChatIcon.Menu = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M5.19234 11.25C4.84861 11.25 4.55434 11.1276 4.30955 10.8828C4.06477 10.638 3.94238 10.3437 3.94238 10C3.94238 9.65626 4.06477 9.362 4.30955 9.11721C4.55434 8.87242 4.84861 8.75002 5.19234 8.75002C5.53609 8.75002 5.83036 8.87242 6.07513 9.11721C6.31992 9.362 6.44232 9.65626 6.44232 10C6.44232 10.3437 6.31992 10.638 6.07513 10.8828C5.83036 11.1276 5.53609 11.25 5.19234 11.25ZM10 11.25C9.65629 11.25 9.36203 11.1276 9.11724 10.8828C8.87245 10.638 8.75005 10.3437 8.75005 10C8.75005 9.65626 8.87245 9.362 9.11724 9.11721C9.36203 8.87242 9.65629 8.75002 10 8.75002C10.3438 8.75002 10.638 8.87242 10.8828 9.11721C11.1276 9.362 11.25 9.65626 11.25 10C11.25 10.3437 11.1276 10.638 10.8828 10.8828C10.638 11.1276 10.3438 11.25 10 11.25ZM14.8077 11.25C14.464 11.25 14.1697 11.1276 13.9249 10.8828C13.6801 10.638 13.5577 10.3437 13.5577 10C13.5577 9.65626 13.6801 9.362 13.9249 9.11721C14.1697 8.87242 14.464 8.75002 14.8077 8.75002C15.1515 8.75002 15.4457 8.87242 15.6905 9.11721C15.9353 9.362 16.0577 9.65626 16.0577 10C16.0577 10.3437 15.9353 10.638 15.6905 10.8828C15.4457 11.1276 15.1515 11.25 14.8077 11.25Z'
			fill='#ffffff'
		/>
	</svg>
)

ChatIcon.Cross = () => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.46665 15.7628L0.237183 14.5333L6.77052 8L0.237183 1.46667L1.46665 0.237204L7.99998 6.77054L14.5333 0.237204L15.7628 1.46667L9.22944 8L15.7628 14.5333L14.5333 15.7628L7.99998 9.22946L1.46665 15.7628Z'
			fill='#ffffff'
		/>
	</svg>
)

ChatIcon.Notification = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='10' cy='10' r='9' fill='#FF9531' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.40002 15H10.65V8H9.40002V15Z' fill='white' />
		<path d='M11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z' fill='white' />
	</svg>
)

ChatIcon.Check = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'>
		<rect width='28' height='28' rx='14' fill='#0067B2' />
		<path d='M8 14L12 18L20 10' stroke='white' strokeWidth='2' />
	</svg>
)

ChatIcon.Check2 = () => (
	<svg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M5.70001 10.6178L14.6846 1.63319L13.5231 0.492188L5.70001 8.32809L1.75131 4.37935L0.623108 5.52035L5.70001 10.6178Z' fill='#E0E0E0' />
	</svg>
)

ChatIcon.CheckOff = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#999999' />
	</svg>
)

ChatIcon.CheckOn = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width='24' height='24' rx='4' fill='#0067B2' />
		<path d='M10.525 16.2134L17.2634 9.47497L16.3923 8.61922L10.525 14.4961L7.56344 11.5346L6.71729 12.3903L10.525 16.2134Z' fill='white' />
	</svg>
)

ChatIcon.Close = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'>
		<path d='M0.964284 8.99999L0 8.03571L8.0357 0L8.99998 0.964285L0.964284 8.99999Z' fill='white' />
		<path d='M1.98714e-05 0.964294L0.964303 9.1308e-06L9 8.03572L8.03572 9L1.98714e-05 0.964294Z' fill='white' />
	</svg>
)

ChatIcon.RadioOff = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.7' y='0.7' width='22.6' height='22.6' rx='11.3' stroke='#0067B2' strokeWidth='1.4' />
	</svg>
)

ChatIcon.RadioOn = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='12' cy='12' r='11.3' stroke='#0067B2' strokeWidth='1.4' />
		<circle cx='12' cy='12' r='6' fill='#0A457E' />
	</svg>
)

ChatIcon.Arrow = () => (
	<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M6.00002 7.03844L0.346191 1.38464L1.40002 0.330811L6.00002 4.93081L10.6 0.330811L11.6538 1.38464L6.00002 7.03844Z' fill='#03070A' />
	</svg>
)

ChatIcon.Close = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'>
		<path d='M0.964284 8.99999L0 8.03571L8.0357 0L8.99998 0.964285L0.964284 8.99999Z' fill='white' />
		<path d='M1.98714e-05 0.964294L0.964303 9.1308e-06L9 8.03572L8.03572 9L1.98714e-05 0.964294Z' fill='white' />
	</svg>
)

export default ChatIcon
