import styles from "./Button.module.scss"

export default function Button({
	disabled,
	message = "",
	title = "",
	depType = "",
	onClick = () => {},
	fullwidth = false,
	type = "secondary",
	size = "medium",
	children,
	icon,
}) {
	return (
		<button
			disabled={disabled}
			onClick={() => onClick(title, message, depType)}
			className={`${styles.button} ${styles[type]} ${styles[size]}`}
			style={fullwidth ? {width: "100%"} : null}
		>
			{children}
			{icon && <div className={styles.icon}>{icon}</div>}
		</button>
	)
}
