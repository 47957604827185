const host = 'https://test2.maximum-haval.ru';

export async function getDataFromApi(brand, site) {
	try {
		const response = await fetch(`${host}/api/v1/multiadmin/${brand?.toLowerCase()}?site=${site}`);
		return await response.json()

	} catch (error) {
		console.error('Error:', error);
		throw error;
	}
}
